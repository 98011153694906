import moment from 'moment'
import { INotification, TypeCategoryNotifications } from '../../typing'
import styles from './styles.module.css'
import { CountriesFor } from '../contries-for'
import { useTranslation } from 'react-i18next'

interface IProps {
    item: INotification
    isFull: boolean
    isLast: boolean
    isRead?: boolean
}
export const NotificationItem = ({ item, isFull, isLast, isRead }: IProps) => {
    const { t } = useTranslation()

    const notificationLabelOptions: any = {
        [TypeCategoryNotifications.GeopoliticalConflicts]: t('geoConflicts'),
        [TypeCategoryNotifications.InternalSocialTensions]:
            t('internalConflicts'),
        [TypeCategoryNotifications.PotentialInsecurity]: t(
            'potentialInsecurity',
        ),
        [TypeCategoryNotifications.ClimateEnvironmental]: t('climate'),
        [TypeCategoryNotifications.MedicalRisk]: t('medical'),
        [TypeCategoryNotifications.FemaleTravel]: t('women'),
        [TypeCategoryNotifications.LGBT]: t('LGBTQ'),
        [TypeCategoryNotifications.BordersEntry]: t('bordersEntry'),
        [TypeCategoryNotifications.TransportUpdates]: t('transportUpdates'),
    }
    return (
        <div
            className={`${styles.container} ${isLast || isFull ? styles.last_notification : styles.notification_border}`}>
            <div>
                <div className={styles.header}>
                    <p className={styles.title}>{item.title}</p>

                    {isRead ? null : (
                        <div
                            className={`${styles.mark_unread}${isFull ? ` ${styles.mark_unread_full}` : ''}`}
                        />
                    )}
                </div>
                <CountriesFor listCode={item?.countries} />
            </div>

            <p
                className={`${styles.content} ${isFull ? styles.full : styles.short}${isFull && !isRead ? ` ${styles.limit_content}` : ''}`}
                dangerouslySetInnerHTML={{ __html: item.content }}
            />
            {item?.additionalTypes ? (
                <div className={styles.aditionalField}>
                    <div className={styles.icon_container} />
                    <p className={styles.aditionalTxt}>
                        *also relates to{' '}
                        {item?.additionalTypes
                            .map((it: string) => notificationLabelOptions[it])
                            .join(', ')}
                    </p>
                </div>
            ) : null}

            <p className={styles.date}>
                {moment(item.createdAt).format('DD.MM.YY')}
            </p>
        </div>
    )
}
